import { printKitchenReceipts, printReceipt, openDrawer } from "../helpers/printHelper";
import * as Sentry from "@sentry/vue";

export default {
  methods: {
    markUpdated: function (itemIndex, reason = undefined) {
      let orderCanceled = false;
      if (itemIndex !== null) {
        if (this.currentOrder.items[itemIndex]
          && this.currentOrder.items[itemIndex].printers.length > 0
          && this.currentOrder.items[itemIndex].sent_to_kitchen) {
          this.currentOrder.items[itemIndex].has_kitchen_updates = true;
        }
      } else if (itemIndex === null && reason === "order cancelled") {
        orderCanceled = true;
      }
      const doesItemHaveUpd = this.currentOrder.items[itemIndex]
        && ((this.currentOrder.items[itemIndex].has_kitchen_updates
          && this.currentOrder.items[itemIndex].void_status !== 1));
      const isItemCanceledAfterPrint = this.currentOrder.items[itemIndex]
        && this.currentOrder.items[itemIndex].has_kitchen_updates
        && this.currentOrder.items[itemIndex].sent_to_kitchen && this.currentOrder.items[itemIndex].void_status === 1;
      if (this.currentOrder.receipt_kitchen_printed && (doesItemHaveUpd || isItemCanceledAfterPrint || orderCanceled)) {
        this.printKitchenCheck(reason);
      }
    },
    openDrawer: async function () {
      this.$log.debug("Open Drawer");
      try {
        await openDrawer();
      } catch (e) {
        Sentry.captureException(e);
        this.$store.dispatch("errorModal/displayModalError", {
          modalId: "print-error",
          errorBody: {
            title: "Drawer error",
            info: "Please, check that you have a correct IP for printer and Qz tray is running"
          }
        });
        console.error("could not print open drawer", e);
      }
    },
    printCheck: async function () {
      const printPdf = this.terminal.db_settings.find((item) => item.name === "printer_pdf")
        && this.terminal.db_settings.find((item) => item.name === "printer_pdf")["value"] === "true";
      if (printPdf) {
        window.print();
      } else {
        this.$log.debug("printCheck");
        try {
          await printReceipt(this.currentOrder);
          await this.sync();
        } catch (e) {
          Sentry.captureException(e);
          this.$store.dispatch("errorModal/displayModalError", {
            modalId: "print-error",
            errorBody: {
              title: "Printer error",
              info: "Please, check that you have a correct IP for printer and Qz tray is running"
            }
          });
          console.error("could not print receipt", e);
        }
      }
    },
    printKitchenCheck: async function (reason = undefined) {
      if (!this.kitchenUpdatesAvailable && reason === undefined)
        return
      this.isKitchenPrintProcessing = true;
      const self = this;
      let kItemsToPrint = this.kitchenItemsAtCurrentOrder.filter(item => ((item.void_status !== 1 || item.sent_to_kitchen) && item.quantity > 0));
      try {
        if (reason === "order cancelled") {
          kItemsToPrint = kItemsToPrint.filter((i) => !!i.sent_to_kitchen && i.void_status === 0);
          console.log("K ITMJ",kItemsToPrint)
          kItemsToPrint.forEach(function (value) {
            const index = self.currentOrder.items.findIndex((item) => item.id === value.id);
            self.currentOrder.items[index].has_kitchen_updates = true;
          });
        } else if (reason === "qty changed" || reason === "item canceled") {
          kItemsToPrint = kItemsToPrint.filter((i) => !!i.sent_to_kitchen);
                  }
        console.warn('kItemsToPrint', kItemsToPrint);
        let printResults = {
          errors:[]
        }
        if (kItemsToPrint.length){
          printResults = await printKitchenReceipts(this.currentOrder, kItemsToPrint, reason);
        }
        this.currentOrder.receipt_kitchen_printed = true;
        kItemsToPrint.forEach(function (value) {
          const index = self.currentOrder.items.findIndex((item) => item.id === value.id);
        let itemFromError;
        for (const currentError of printResults.errors) {
        console.log(currentError);
        itemFromError = currentError.objectToPrint.items.find(it => it.id === value.id);
        }
          if (!itemFromError) {
            self.currentOrder.items[index].has_kitchen_updates = false;
        if (!reason) {
              self.currentOrder.items[index].sent_to_kitchen = true;
        }
          }
        });
        this.$store.commit('orders/saveOrder', this.currentOrder)
        this.$store.dispatch('sync')

        this.isKitchenPrintProcessing = false;
        // if(object.hasOwn())
        if (printResults.errors.length) throw Error("could not print to kitchen...");
      } catch (e) {
        Sentry.captureException(e);
        // this.currentOrder.receipt_kitchen_printed = false;
        this.$store.dispatch("errorModal/displayModalError", {
          modalId: "print-error",
          errorBody: {
            title: "Printer error",
            info: "Please, check that you have a correct IP for kitchen printer and Qz tray is running"
          }
        });
        this.isKitchenPrintProcessing = false;
        this.$log.debug("Could not print kitchen receipt", e);
      }
    }
  },
  computed: {
    kitchenItemsAtCurrentOrder () {
      const products = this.$store.getters["config/products"];
      return this.currentOrder.items.filter(function (item) {
        return (products.find(product => product.id === item.product)).printers.length;
      });
    },
    kitchenUpdatesAvailable() {
      // add void_status === null check for online orders
      const isNewKitchenItemNotPrinted = this.currentOrder.items.filter((e) => (e.void_status === 0 || e.void_status === null ) && !e.sent_to_kitchen && e.printers.length > 0).length > 0;
      const isKitchenUpdates = this.currentOrder.items.filter((e) => (e.void_status === 0 || e.void_status === null ) && e.has_kitchen_updates && e.printers.length > 0).length > 0;
      return isKitchenUpdates || isNewKitchenItemNotPrinted;
    },
    // orderPlaced(){}
  }
};
